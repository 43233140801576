<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("VISIBILITY") }} {{ $t("FORFAIT") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="filtre">
        <p class="table-count">{{ getTotalRowforfait }} {{ $t("FORFAIT") }}</p>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("WORDING") }}
              </th>
              <th>
                {{ $t("REFERENCE") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllforfaits"
              :key="i"
            >
              <td aria-colindex="1" role="cell">
                {{ data.lib }}
              </td>
              <td aria-colindex="5" role="cell">
                {{ data.ref }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowforfait"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowforfait"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getforfaitLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import storeModalVue from "../components/visibility/forfait.vue";

export default {
  components: {
    storeModalVue,
  },
  data() {
    return {
      search: "",
      page: 1,
      perPage: 10,
    };
  },
  methods: {
    ...mapActions(["all_forfaits", "all_entreprises"]),
    async searchprestation() {
      await this.all_forfaits({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      });
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "forfaitModal");
      // this.all_forfaits({
      //   page: 1,
      //   per_page: 10,
      // }).then(() => {
      //   this.$root.$emit("bv::show::modal", "forfaitModal");
      // });
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchprestation();
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;

      if (this.getOnlineUser) {
        this.all_forfaits({
          page: this.page,
          per_page: this.perPage,
        });
      }
    },
  },

  mounted() {
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getAllforfaits",
      "getforfaitLoading",
      "getTotalRowforfait",
      "getTabs",
      "getOnlineUser",
      "getAllentreprises",
      "getentrepriseLoading",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group .icon-balance {
  top: 8px;
}
.body {
  padding: 4px 0;
  box-shadow: 1px 1px 8px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  align-items: center;
  .messageError {
    top: 5px;
    bottom: 44px;
    color: #e4261b;
    font-size: 15px;
    background-color: #fff;
    border-color: #fff;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}
</style>
